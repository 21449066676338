/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    blockquote: "blockquote",
    h3: "h3",
    code: "code",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "input-array-of-numbers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#input-array-of-numbers",
    "aria-label": "input array of numbers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Input Array of numbers"), "\n", React.createElement(_components.p, null, "and a simple addition program for Competitive Programming and more in Rust"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "For many applications, including Competitive Programming, it is frequently required to take an array of space separated numbers from a user as string and covert it to an Array / Vector of numbers or any desired type."), "\n", React.createElement(_components.p, null, "So, it is better to make a function and just call it instead of rewriting it again and again. It should be ", React.createElement(_components.strong, null, "included in your template"), ", if you are doing Competitive Programming in Rust."), "\n", React.createElement(_components.h2, {
    id: "what-are-we-doing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-we-doing",
    "aria-label": "what are we doing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are we doing"), "\n", React.createElement(_components.p, null, "In Rust, and most other programming languages, ", React.createElement(_components.strong, null, "input is taken as string of characters"), ". So, if it is written as an array of space separated strings, it is still a string in rust."), "\n", React.createElement(_components.p, null, "We want to separate the numbers and store them in an array or a vector."), "\n", React.createElement(_components.h2, {
    id: "addition-program",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#addition-program",
    "aria-label": "addition program permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Addition Program"), "\n", React.createElement(_components.p, null, "So, let's start with designing an addition program. In this program, user can input any number of integers in a single line and output will be sum the sum of all number."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example :"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Input :")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "123  234 345 456 567"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Expected Output :")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "1725"), "\n"), "\n", React.createElement(_components.h3, {
    id: "1-declare-the-function-and-string",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-declare-the-function-and-string",
    "aria-label": "1 declare the function and string permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. Declare the function and string"), "\n", React.createElement(_components.p, null, "You can replace ", React.createElement(_components.code, null, "usize"), " with any data type you want, provide it can be parsed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn take_vector() -> Vec<usize> {\n    // Take the string input\n\n    let mut input = String::new();\n}\n")), "\n", React.createElement(_components.h3, {
    id: "2-take-input-into-string",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-take-input-into-string",
    "aria-label": "2 take input into string permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. Take Input into string"), "\n", React.createElement(_components.p, null, "Now,  to obtain user input, we need to bring the ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "io")), " input/output library into scope. This is present in standard library, called ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "std"))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n")), "\n", React.createElement(_components.p, null, "Now, we read and store input in ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "input")), " string  we created earlier."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "io::stdin()\n    .read_line(&mut input)\n    .unwrap();\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(_components.code, null, "unwrap()")), " specifies that program will panic if, somehow, string cannot be read"), "\n", React.createElement(_components.h3, {
    id: "3-converting-string-into-a-array",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-converting-string-into-a-array",
    "aria-label": "3 converting string into a array permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. Converting String into a array"), "\n", React.createElement(_components.p, null, "Now, we will convert the string into the array of numbers ", React.createElement(_components.strong, null, "using map method")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let arr: Vec<usize> = input\n    .trim()\n    .split_whitespace()\n    .map(|x| x.parse().unwrap())\n    .collect();\n\n// Return the array\nreturn arr;\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, React.createElement(_components.code, null, ".trim()")), " is used to trim any leading or trailing whitespaces. ", React.createElement("br"), "\n", React.createElement(_components.strong, null, React.createElement(_components.code, null, ".split_whitespace()")), " is used to split the string, on each whitespace. ", React.createElement("br"), "\n", React.createElement(_components.strong, null, React.createElement(_components.code, null, ".map(|x|)")), " is used to perform operation on each part of string, that we made using above function. Each part is represented as x", React.createElement("br"), "\n", React.createElement(_components.strong, null, React.createElement(_components.code, null, ".parse()")), " is used to convert the string to number. Interestingly, you don't have to tell the data type in this case, because Rust automatically determines it using return data type. Cool, no? ", React.createElement("br"), "\n", React.createElement(_components.strong, null, React.createElement(_components.code, null, "unwrap()")), " specifies that program will panic if, string can not be converted to number. For example if you input ", React.createElement(_components.code, null, "12h"), ", ", React.createElement(_components.code, null, "23 34"), " it will panic because it is not convertible to number. You must enter 1 number per line."), "\n", React.createElement(_components.h3, {
    id: "4-putting-things-together",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#4-putting-things-together",
    "aria-label": "4 putting things together permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "4. Putting things together"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n\nfn take_vector() -> Vec<usize> {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    let arr: Vec<usize> = input.trim().split_whitespace()\n        .map(|x| x.parse().unwrap()).collect();\n    return arr;\n}\n")), "\n", React.createElement(_components.h3, {
    id: "5-program-with-driver-code",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#5-program-with-driver-code",
    "aria-label": "5 program with driver code permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "5. Program With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n\nfn take_vector() -> Vec<usize> {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    let arr: Vec<usize> = input.trim().split_whitespace()\n        .map(|x| x.parse().unwrap()).collect();\n    return arr;\n}\n\n// Driver Code\n\nfn main(){\n    let arr = take_vector();\n    let mut sum = 0;\n    for i in 0..arr.len(){\n        sum+=arr[i];\n    }\n    println!(\"{}\", sum);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input :")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "123  234 345 456 567"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Expected Output :")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "1725"), "\n"), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "In this article, we designed a function that take an array of space separated numbers from a user as string and covert it to an Array / Vector of numbers or any desired type."), "\n", React.createElement(_components.p, null, "Here is the function to summarize and copy and use purposes"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io;\n\nfn take_vector() -> Vec<usize> {\n    let mut input = String::new();\n    io::stdin().read_line(&mut input).unwrap();\n    let arr: Vec<usize> = input.trim().split_whitespace()\n        .map(|x| x.parse().unwrap()).collect();\n    return arr;\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " We can change the data type of number returned by replacing ", React.createElement(_components.strong, null, React.createElement(_components.code, null, "usize")), " in above function with desired data type."), "\n", React.createElement(_components.p, null, "We also designed a simple addition program, in which user can input any number of integers in a single line and output will be sum the sum of all number."), "\n", React.createElement(_components.p, null, "In my humble opinion, if you are doing competitive programming in rust, you should ", React.createElement(_components.strong, null, "include this function in your template"), " for quick access."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Function to Input Array of numbers - Rust Programming",
    description: "Take an array of space separated numbers from user as string and convert it to vector of numbers. We will make a function for this in Rust Language."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
